import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ImageSelectionService } from '../../service/image-selection.service';
import { GeneratorService } from 'src/app/deebr/service/generator.service';

type ResizeDirection = 'nw' | 'ne' | 'sw' | 'se' | null;

@Component({
  selector: 'app-write-on-image',
  templateUrl: './write-on-image.component.html',
  styleUrls: ['./write-on-image.component.scss']
})
export class WriteOnImageComponent implements OnInit, AfterViewInit {
  @Input() staticImageUrl!: string;
  @Input() imageOptions: string[] = []; // Changed type to string[]

  selectedImageUrl: string = '';
  isLoading: boolean = true; 
  selectedOption: string | null = null; // Changed type to string | null

  // Overlay-related properties
  @ViewChild('overlayContainer', { static: false }) overlayContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('overlayImg', { static: false }) overlayImg!: ElementRef<HTMLImageElement>;
  @ViewChild('staticImgEl', { static: false }) staticImgEl!: ElementRef<HTMLImageElement>;

  isDragging = false;
  isResizing = false;
  resizeDirection: ResizeDirection = null;

  startX = 0;
  startY = 0;
  currentX = 0;
  currentY = 0;
  startWidth = 0;
  startHeight = 0;
  currentWidth = 0;
  currentHeight = 0;
  resizeStartX = 0;
  resizeStartY = 0;
  loading = false;
  scaleFactor = 1;
  currentOverlayUrl = ''; // The overlay image URL chosen from imageOptions (text_only)
  firstLoadDone = false;

  constructor(
    protected dialogRef: NbDialogRef<WriteOnImageComponent>,
    private imageSelectionService: ImageSelectionService,
    private generatorService: GeneratorService
  ) {}

  ngOnInit(): void {
    console.log('Image options:', this.imageOptions);
    this.isLoading = !this.imageOptions || this.imageOptions.length === 0;
    
    this.imageSelectionService.imageOptions$.subscribe(options => {
      this.isLoading = !options || options.length === 0;
      if (options.length > 0) {
        this.imageOptions = options;
        this.selectedImageUrl = options[0];
        this.selectedOption = options[0];
        this.currentOverlayUrl = options[0];
      } else {
        this.isLoading = true;
      }
    }, error => {
      console.error('Error fetching image options:', error);
      this.isLoading = false;
      this.imageSelectionService.resetImageOptions();
      this.dialogRef.close(undefined);
    });
  }

  ngAfterViewInit(): void {
    if (this.staticImgEl?.nativeElement) {
      this.staticImgEl.nativeElement.onload = () => {
        this.onStaticImageLoad();
        this.initializeOverlay();
      };
    }

    document.addEventListener('mouseup', (e) => this.onMouseUp(e));
    document.addEventListener('mousemove', (e) => this.onMouseMove(e));
  }

  private initializeOverlay() {
    if (this.overlayImg?.nativeElement) {
      const overlayEl = this.overlayImg.nativeElement;
      overlayEl.onload = () => {
        setTimeout(() => {
          if (!this.firstLoadDone) {
            this.initializeOverlayDimensions();
            this.firstLoadDone = true;
          }
        }, 100);
      };
    }
  }

  onStaticImageLoad() {
    const container = this.overlayContainer.nativeElement;
    const staticImg = this.staticImgEl.nativeElement;

    const maxHeight = window.innerHeight * 0.75;
    const maxWidth = window.innerWidth * 0.75;

    const imgRatio = staticImg.naturalWidth / staticImg.naturalHeight;

    let adjustedWidth = staticImg.naturalWidth;
    let adjustedHeight = staticImg.naturalHeight;

    if (adjustedHeight > maxHeight) {
      adjustedHeight = maxHeight;
      adjustedWidth = adjustedHeight * imgRatio;
    }

    if (adjustedWidth > maxWidth) {
      adjustedWidth = maxWidth;
      adjustedHeight = adjustedWidth / imgRatio;
    }

    this.scaleFactor = adjustedWidth / staticImg.naturalWidth;

    staticImg.style.width = `${adjustedWidth}px`;
    staticImg.style.height = `${adjustedHeight}px`;

    container.style.width = `${adjustedWidth}px`;
    container.style.height = `${adjustedHeight}px`;

    // Only initialize overlay on first load
    if (!this.firstLoadDone && this.overlayImg) {
      this.initializeOverlayDimensions();
    }
  }

  initializeOverlayDimensions() {
    if (!this.overlayImg?.nativeElement || !this.overlayContainer?.nativeElement) return;
    
    const overlayEl = this.overlayImg.nativeElement;
    const containerEl = this.overlayContainer.nativeElement;

    // Wait for natural dimensions to be available
    if (!overlayEl.naturalWidth || !overlayEl.naturalHeight) {
      setTimeout(() => this.initializeOverlayDimensions(), 100);
      return;
    }
  
    this.currentWidth = overlayEl.naturalWidth * this.scaleFactor;
    this.currentHeight = overlayEl.naturalHeight * this.scaleFactor;
  
    this.currentX = (containerEl.clientWidth - this.currentWidth) / 2;
    this.currentY = (containerEl.clientHeight - this.currentHeight) / 2;
  
    this.updateOverlayStyles();
  }

  updateOverlayImage() {
    if (this.selectedOption) {
      this.currentOverlayUrl = this.selectedOption;
      
      if (this.overlayImg) {
        const overlayEl = this.overlayImg.nativeElement;
        
        // Store current dimensions and position
        const currentDimensions = {
          x: this.currentX,
          y: this.currentY,
          width: this.currentWidth,
          height: this.currentHeight
        };
        
        overlayEl.onload = () => {
          if (!this.firstLoadDone) {
            // Only initialize dimensions on first load
            this.initializeOverlayDimensions();
            this.firstLoadDone = true;
          } else {
            // Restore previous dimensions and position
            this.currentX = currentDimensions.x;
            this.currentY = currentDimensions.y;
            this.currentWidth = currentDimensions.width;
            this.currentHeight = currentDimensions.height;
            this.updateOverlayStyles();
          }
        };
      }
    }
  }

  selectOption(option: string) {
    this.selectedImageUrl = option;
    this.selectedOption = option;
    // Position and dimensions will be preserved by updateOverlayImage
    this.updateOverlayImage();
  }

  cancel() {
    this.imageSelectionService.resetImageOptions();
    this.dialogRef.close(null);
  }

  // Confirm merges images
  confirm() {
    this.loading = true;

    const scaledX = Math.round(this.currentX / this.scaleFactor);
    const scaledY = Math.round(this.currentY / this.scaleFactor);
    const scaledWidth = Math.round(this.currentWidth / this.scaleFactor);
    const scaledHeight = Math.round(this.currentHeight / this.scaleFactor);

    if (!this.selectedOption) {
      // No option selected
      this.loading = false;
      this.dialogRef.close(null);
      return;
    }

    this.generatorService.mergeImages(
      this.staticImageUrl,
      this.selectedOption,  // Now passing the URL directly
      scaledX,
      scaledY,
      scaledWidth,
      scaledHeight
    ).subscribe(
      (response) => {
        this.loading = false;
        const mergedImageUrl = typeof response === 'string' ? response : response?.merged_image_url;

        if (mergedImageUrl) {
          this.dialogRef.close({ mergedImageUrl });
        } else {
          console.error('Invalid response format:', response);
          this.dialogRef.close({ mergedImageUrl: undefined });
        }
      },
      (error) => {
        this.loading = false;
        console.error('Error merging images:', error);
        this.dialogRef.close(undefined);
      }
    );
  }

  // Overlay handlers (from overlay-images-modal)
  onImageMouseDown(event: MouseEvent) {
    if (this.isResizing) return;
    this.isDragging = true;
    this.startX = event.clientX - this.currentX;
    this.startY = event.clientY - this.currentY;
    event.preventDefault();
    event.stopPropagation();
  }

  onResizeHandleDown(event: MouseEvent, direction: ResizeDirection) {
    this.isResizing = true;
    this.resizeDirection = direction;
    this.startWidth = this.currentWidth;
    this.startHeight = this.currentHeight;
    this.resizeStartX = event.clientX;
    this.resizeStartY = event.clientY;
    event.preventDefault();
    event.stopPropagation();
  }

  onMouseUp(event: MouseEvent) {
    this.isDragging = false;
    this.isResizing = false;
    this.resizeDirection = null;
  }

  onMouseMove(event: MouseEvent) {
    if (!this.overlayContainer) return;
    const containerRect = this.overlayContainer.nativeElement.getBoundingClientRect();
  
    // Moving the image
    if (this.isDragging && !this.isResizing) {
      let newX = event.clientX - this.startX;
      let newY = event.clientY - this.startY;
  
      newX = Math.max(0, Math.min(newX, containerRect.width - this.currentWidth));
      newY = Math.max(0, Math.min(newY, containerRect.height - this.currentHeight));
  
      this.currentX = Math.round(newX);
      this.currentY = Math.round(newY);
      this.updateOverlayStyles();
      return;
    }
  
    // Resizing the image
    if (this.isResizing && this.resizeDirection) {
      const deltaX = event.clientX - this.resizeStartX;
      const deltaY = event.clientY - this.resizeStartY;
  
      let newWidth = this.currentWidth;
      let newHeight = this.currentHeight;
  
      const aspectRatio = this.startWidth / this.startHeight;
  
      switch (this.resizeDirection) {
        case 'nw': {
          newWidth = this.startWidth - deltaX;
          newHeight = newWidth / aspectRatio;
          this.currentX += this.currentWidth - newWidth;
          this.currentY += this.currentHeight - newHeight;
          break;
        }
        case 'ne': {
          newWidth = this.startWidth + deltaX;
          newHeight = newWidth / aspectRatio;
          this.currentY += this.currentHeight - newHeight;
          break;
        }
        case 'sw': {
          newWidth = this.startWidth - deltaX;
          newHeight = newWidth / aspectRatio;
          this.currentX += this.currentWidth - newWidth;
          break;
        }
        case 'se': {
          newWidth = this.startWidth + deltaX;
          newHeight = newWidth / aspectRatio;
          break;
        }
      }
  
      newWidth = Math.max(newWidth, 10);
      newHeight = Math.max(newHeight, 10);
  
      if (this.currentX < 0) {
        this.currentX = 0;
      }
      if (this.currentY < 0) {
        this.currentY = 0;
      }
      if (this.currentX + newWidth > containerRect.width) {
        newWidth = containerRect.width - this.currentX;
        newHeight = newWidth / aspectRatio;
      }
      if (this.currentY + newHeight > containerRect.height) {
        newHeight = containerRect.height - this.currentY;
        newWidth = newHeight * aspectRatio;
      }
  
      this.currentWidth = Math.round(newWidth);
      this.currentHeight = Math.round(newHeight);
      this.updateOverlayStyles();
    }
  }

  updateOverlayStyles() {
    if (!this.overlayImg) return;
    const overlayWrapper = this.overlayImg.nativeElement.parentElement as HTMLElement;
    overlayWrapper.style.transform = `translate(${this.currentX}px, ${this.currentY}px)`;
    overlayWrapper.style.width = `${this.currentWidth}px`;
    overlayWrapper.style.height = `${this.currentHeight}px`;
  }
}