import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ImageSelectionService {
  private imageOptionsSubject = new BehaviorSubject<string[]>([]);
  public imageOptions$ = this.imageOptionsSubject.asObservable();
  
  private imageUrlsSource = new BehaviorSubject<string[]>([]);
  public imageUrls$ = this.imageUrlsSource.asObservable();

  updateImageUrls(urls: string[]) {
    this.imageUrlsSource.next(urls);
  }

  resetImageUrls() {
    this.imageUrlsSource.next([]);
  }

  updateImageOptions(imageOptions: string[]): void {
    this.imageOptionsSubject.next(imageOptions);
  }

  resetImageOptions(): void {
    this.imageOptionsSubject.next([]);
  }
}
