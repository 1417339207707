import { LOCALE_ID, NgModule, Provider } from '@angular/core';
import { GridsterModule } from 'angular-gridster2';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  NbAlertModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbChatModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbThemeModule,
  NbToastrModule,
  NbTreeGridModule,
  NbWindowModule,
  NbButtonGroupModule,
  NbProgressBarModule,
  NbTooltipModule,
  NbFormFieldModule
} from '@nebular/theme';
import { NbAuthModule, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';

import { LoggedInGuard } from './deebr/guard/logged-in.guard';
import { DeebrLoginComponent } from './deebr/component/auth/deebr-login/deebr-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './deebr/component/pages/dashboard/dashboard.component';
import { HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BearerInterceptor } from './deebr/interceptor/bearer.interceptor';
import { NgChartsModule } from 'ng2-charts';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CampaignComponent } from './deebr/component/pages/campaign/campaign.component';
import { TreemapComponent } from './deebr/component/treemap/treemap.component';
import { DicoService } from './deebr/service/dico.service';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CampaignSegmentEditorComponent } from './deebr/component/campaign-segment-editor/campaign-segment-editor.component';
import localeFr from '@angular/common/locales/fr';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import { MailsService } from './deebr/service/mails.service';
import { LogoutComponent } from './deebr/component/auth/logout/logout.component';
import { VennDiagrammComponent } from './deebr/component/venn-diagramm/venn-diagramm.component';
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbPaginationModule,
  NgbProgressbarModule,
  NgbToastModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ResetPasswordComponent } from './deebr/component/auth/reset-password/reset-password.component';
import { RequestPasswordComponent } from './deebr/component/auth/request-password/request-password.component';
import { ReactiveComponentModule } from '@ngrx/component';
import { TableComponent } from './deebr/component/table/table.component';
import { ReportComponent } from './deebr/component/pages/report/report.component';
import { ReportStore } from './deebr/component/pages/report/report.store';
import { UserStore } from './deebr/store/user.store';
import { GeneratorEditComponent } from './deebr/component/pages/generator/generator-edit.component';
import { GeneratorListComponent } from './deebr/component/pages/generator/generator-list.component';
import { EmailStore } from './deebr/component/pages/generator/email.store';
import { ImageModalComponent } from './deebr/component/image-modal/image-modal.component';
import { EmailPreviewPopupComponent } from './deebr/component/pages/generator/email-preview-popup/email-preview-popup.component';
import { ImageChoiceComponent } from './deebr/component/image-choice/image-choice.component';
import Hotjar from '@hotjar/browser';
import { TemplateModalComponent } from './deebr/component/template-modal/template-modal.component';
import { FireflyChoiceComponent } from './deebr/component/firefly-choice/firefly-choice.component';
import { DragDropProductsComponent } from './deebr/component/drag-drop-products/drag-drop-products.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImageToGifDialogComponent } from './deebr/component/pages/generator/image-to-gif-dialog/image-to-gif-dialog.component'
import { CreateGifProductComponent } from './deebr/component/create-gif-product/create-gif-product.component';
import { ResizeImageModalComponent } from './deebr/component/pages/generator/resize-image-modal/resize-image-modal.component';
import { ProductRecommandationComponent } from './deebr/component/pages/product-recommandation/product-recommandation.component';
import { WriteOnImageComponent } from './deebr/component/write-on-image/write-on-image.component';

const siteId = 3757797; // Remplacez ceci par votre propre siteId
const hotjarVersion = 6; // Utilisez la version Hotjar que vous souhaitez

Hotjar.init(siteId, hotjarVersion);

registerLocaleData(localeFr, 'fr');
let services = [CoreModule, NbSidebarModule, NbMenuModule, NbThemeModule];

let providers: Provider[] = [
  LoggedInGuard,
  DicoService,
  MailsService,
  DecimalPipe,
  { provide: LOCALE_ID, useValue: 'fr-FR' },
  {
    provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
    useValue: function (req: HttpRequest<any>) {
      if (req.url === '/api/token/refresh/' || req.url === '/api/token/') {
        return true;
      }
      return false;
    },
  },
  { provide: HTTP_INTERCEPTORS, useClass: BearerInterceptor, multi: true },
  ReportStore,
  EmailStore,
  UserStore,
];

//workaround method for adding provider (ts compiler gives an error otherwise)
services.forEach((service) => {
  if (service.forRoot() && service.forRoot().providers !== undefined) {
    service.forRoot().providers?.forEach((x) => {
      providers.push(x);
    });
  }
});

@NgModule({
  declarations: [
    AppComponent,
    DeebrLoginComponent,
    DashboardComponent,
    CampaignComponent,
    ReportComponent,
    GeneratorEditComponent,
    GeneratorListComponent,
    TreemapComponent,
    CampaignSegmentEditorComponent,
    LogoutComponent,
    VennDiagrammComponent,
    ResetPasswordComponent,
    RequestPasswordComponent,
    TableComponent,
    ImageModalComponent,
    EmailPreviewPopupComponent,
    ImageChoiceComponent,
    TemplateModalComponent,
    FireflyChoiceComponent,
    DragDropProductsComponent,
    ImageToGifDialogComponent,
    CreateGifProductComponent,
    ResizeImageModalComponent,
    ProductRecommandationComponent,
    WriteOnImageComponent,
  ],
  imports: [
    DragDropModule,
    GridsterModule,
    NbCardModule,
    NbTooltipModule,
    BrowserModule,
    AppRoutingModule,
    NbChatModule,
    NbDatepickerModule.forRoot(),
    NbMenuModule,
    NbSidebarModule,
    NbToastrModule,
    NbWindowModule,
    CoreModule,
    ThemeModule,
    NbTreeGridModule,
    BrowserAnimationsModule,
    NbAuthModule.forRoot(),
    NbAlertModule,
    NbInputModule,
    NbButtonModule,
    NbLayoutModule,
    NbCheckboxModule,
    FormsModule,
    NbIconModule,
    NgChartsModule,
    NgxSliderModule,
    NgApexchartsModule,
    NbProgressBarModule,
    ReactiveFormsModule,
    NbSelectModule,
    NbSpinnerModule,
    NgbPaginationModule,
    NgbDatepickerModule,
    NgbTypeaheadModule,
    NgbToastModule,
    NgbDropdownModule,
    NbDialogModule.forRoot(),
    ReactiveComponentModule,
    NbButtonGroupModule,
    NgbProgressbarModule,
    NbFormFieldModule
  ],
  providers: providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
