import { Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import { debounceTime, filter, Observable, Subject, take, takeUntil, firstValueFrom, lastValueFrom  } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { EmailStore,  Email } from './email.store';
import { DownloadService } from 'src/app/deebr/service/download.service';
import { UserService } from 'src/app/deebr/service/user.service';
import {GeneratorService} from 'src/app/deebr/service/generator.service';
import { FormBuilder, FormGroup, FormControl  } from '@angular/forms';
import { tap, catchError } from 'rxjs/operators';
import { User } from 'src/app/deebr/store/user.store';
import { EmailPreviewPopupComponent } from 'src/app/deebr/component/pages/generator/email-preview-popup/email-preview-popup.component';
import { ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { ToastService } from 'src/app/deebr/service/toast.service';
import { NbDialogService, NbDialogRef } from '@nebular/theme';
import {WriteOnImageComponent} from 'src/app/deebr/component/write-on-image/write-on-image.component';
import { ImageSelectionService } from '../../../service/image-selection.service';
import { LoggingService } from 'src/app/deebr/service/logging.service';
import { ImageToGifDialogComponent } from './image-to-gif-dialog/image-to-gif-dialog.component';
import { EntrepriseService } from 'src/app/deebr/service/entreprise.service';
import { ResizeImageModalComponent } from './resize-image-modal/resize-image-modal.component';
import * as JSZip from 'jszip';

interface ImageData {
  url_with_text: string;
  text_only: string;
}

interface ImageResponse {
  statut: string;
  [key: string]: ImageData | string | undefined;
}

@Component({
  templateUrl: './generator-edit.component.html',
  styleUrls: ['./generator-edit.component.scss'],
})

export class GeneratorEditComponent implements OnInit, OnDestroy {
  @ViewChild(EmailPreviewPopupComponent) emailPreviewPopupComponent!: EmailPreviewPopupComponent;
  private destroy$: Subject<void> = new Subject();
  public userId!: number;
  public userName!: string;
  public form!: FormGroup;
  public imageForm!: FormGroup;
  public isExporting = false;
  public isSaving = false;
  public showPopup = false;
  private authToken: string | null = null;
  public isLoading = true;
  previewHtmlUrl?: string;
  previewImgUrl?: string;
  emailName: string = '';
  private saveTimeout: any;
  loading: boolean = false;
  dialogRef: NbDialogRef<any> | undefined = undefined;
  private lastSaveTime: number = Date.now();
  @ViewChild('dialogErrorExport') dialogErrorExport!: TemplateRef<any>;
  @ViewChild('dialogErrorAnimate') dialogErrorAnimate!: TemplateRef<any>;
  @ViewChild('dialogErrorWriteOnImage') dialogErrorWriteOnImage!: TemplateRef<any>;
  @ViewChild('dialogExportTracking') dialogExportTracking!: TemplateRef<any>;
  @ViewChild('dialogErrorTracking') dialogErrorTracking!: TemplateRef<any>;
  @ViewChild('dialogPushEmail') dialogPushEmail!: TemplateRef<any>;
  @ViewChild('dialogErrorPushEmail') dialogErrorPushEmail!: TemplateRef<any>;
  errorMessage: string | null = null;
  initialEmailId!: number;
  public isManualSave: boolean = false;
  private isAutoSaving: boolean = false;
  enterpriseTracking!: string;
  enterpriseId!: number;
  enterprisePushEmail: boolean = false;
  public canEditTracking: boolean = false;
  wantsTracking: boolean = false;
  public selectedImageUrl: string = '';
  public imgHeight: number = 0;
  public imgWidth: number = 0;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private readonly emailStore: EmailStore,
    private readonly downloadService: DownloadService,
    private readonly formBuilder: FormBuilder,
    private userService: UserService,
    private generatorService : GeneratorService,
    private toastService: ToastService,
    private dialogService: NbDialogService,
    private imageSelectionService: ImageSelectionService,
    private loggingService: LoggingService,
    private entrepriseService: EntrepriseService
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.initialEmailId = this.route.snapshot.params['id'];
    this.loggingService.log(`Initial email ID: ${this.initialEmailId}`);

    this.imageForm = new FormGroup({
      url: new FormControl(''),
      width: new FormControl(''),
      height: new FormControl('')
    });

    this.userService.getUsers()
    .pipe(takeUntil(this.destroy$))
    .subscribe((user: User[]) => {
      this.userId = user[0].id;
      this.userName = user[0].first_name; 
      this.loggingService.log(`User loaded: ${this.userName} (ID: ${this.userId})`);
    });
    
    this.emailStore
      .selectEmail(this.initialEmailId)
      .pipe(
        takeUntil(this.destroy$),
        filter((email) => !!email),
        take(1)
      )
      .subscribe((email) => {
        this.initEditor(email);
        this.previewHtmlUrl = email.preview_html_url;
        this.previewImgUrl = email.preview_img_url;
        this.emailName = email.name;
        // console.log("this.emailName", this.emailName)
      });
      this.startAutoSave();

      this.entrepriseService.getEntreprise().subscribe(response => {
        if (response.count > 0) {
          this.enterpriseTracking = response.results[0].tracking;
          this.enterpriseId = response.results[0].id;
          this.enterprisePushEmail = response.results[0].push_email;

        }
      });
      
  }

  ngOnDestroy(): void {
    this.save(false); // Sauvegarde avant de détruire le composant
    window.StripoApi.stop();
    this.destroy$.next();
    this.destroy$.complete();
  }

  copyToClipboard(text: string): void {
    if (!text) {
      console.error('Aucun texte à copier');
      return;
    }

    navigator.clipboard.writeText(text).then(() => {
      this.toastService.success('Email Creator', "Lien copié dans le presse-papiers");;
    }).catch(err => {
      console.error('Erreur lors de la copie :', err);
    });
  }

  
  private startAutoSave(): void {
    const fifteenminutes = 15 * 60 * 1000; // 5 seconds in milliseconds
    
    setInterval(() => {
      const currentTime = Date.now();
      
      if (currentTime - this.lastSaveTime >= fifteenminutes && !this.isAutoSaving) {
        this.loggingService.log(`Auto-saving email with ID: ${this.initialEmailId}`);
        this.save(false);
      }
    }, fifteenminutes);
  }

  public async save(manual: boolean = false): Promise<void> {
    this.isManualSave = true;
    if (this.isAutoSaving && !manual) return; // Evite de lancer plusieurs sauvegardes en même temps sauf pour une sauvegarde manuelle
    this.isAutoSaving = !manual; // Set isAutoSaving to false if it's a manual save
    //this.isManualSave = manual;
    
    try {
      if (this.isExporting == false && manual){
        this.isSaving = true;
      }
      const { html, css } = await this.processAndSaveTemplate();
  
      const previewHtmlUrl = this.previewHtmlUrl; // Assurez-vous que ces valeurs sont définies
      const previewImgUrl = this.previewImgUrl;   // Assurez-vous que ces valeurs sont définies
      this.loggingService.log(`Saving email with ID: ${this.initialEmailId}`);
      await this.emailStore.saveEmail({ id: this.initialEmailId, html, css, name : this.emailName, preview_html_url: previewHtmlUrl, preview_img_url: previewImgUrl, isManualSave: manual });
  
      // Mettre à jour le dernier moment de sauvegarde
      this.lastSaveTime = Date.now();
  
      // Créer un point d'historique de version
      this.createVersionHistoryPoint(() => {
        const changeHistoryLink = document.getElementById('changeHistoryLink');
        if (changeHistoryLink) {
          changeHistoryLink.innerHTML = '<i class="bi bi-clock-history"></i>';
        }
      });
  
    } catch (error) {
      console.error('Erreur lors de la sauvegarde :', error);
    } finally {
      if (manual) {
        this.isSaving = false;
        
      }
      this.isManualSave = false;
      this.isAutoSaving = false;
    }
  }

  onEmailNameChange(newName: string): void {
    this.emailName = newName;
  
    if (this.saveTimeout) {
      clearTimeout(this.saveTimeout);
    }
  
    this.saveTimeout = setTimeout(() => {
      this.save(false); // Appelez la méthode save avec false
    }, 1000);
  }

  private createVersionHistoryPoint(onSaveCallback: () => void): void {
    if (window.StripoApi.createVersionHistoryPointAsync) {
      window.StripoApi.createVersionHistoryPointAsync(onSaveCallback);
    } else {
      // Deprecated
      window.StripoApi.createVersionHistoryPoint();
      onSaveCallback();
    }
  }
  
  toggleEditTracking(): void {
    this.canEditTracking = !this.canEditTracking;
    if (!this.canEditTracking) {
      this.enterpriseTracking = this.enterpriseTracking.replace(/ /g, "");
      this.enterpriseTracking = this.enterpriseTracking.replace("?", "");
      // Sauvegarder le tracking modifié via l'API
      this.saveTracking(this.enterpriseTracking);
      this.dialogRef?.close();
      this.dialogRef = undefined; // Réinitialise dialogRef
      this.openExportTrackingDialog();
    }
  }
  
  saveTracking(tracking: string): void {
    if (tracking.startsWith("?")) {
      tracking = tracking.substring(1);
    }
    tracking = tracking.replace(/ /g, "");
    this.generatorService.changeValueInEnterprise(this.enterpriseId, "tracking", tracking).subscribe(
      response => {

      },
      error => {
        console.error('Erreur lors de la mise à jour du tracking :', error);
      }
    );
  }
  
  confirmExport(withTracking: boolean): void {
    if (withTracking) {
      // console.log("withTracking");
      this.wantsTracking = true;
      this.toggleEditTracking()
      this.canEditTracking = false
    } else {
      // console.log("noTracking");
      this.wantsTracking = false;
    }
    this.dialogRef?.close();
    this.dialogRef = undefined; // Réinitialise dialogRef
    this.export();
  }

  public exportProcess(): void {
    if (!this.dialogRef) {
      this.openExportTrackingDialog();
    } else {
      this.export();
    }
  }

  public async export(): Promise<void> {
    try {
      this.isExporting = true;
      console.log("this.enterprisePushEmail", this.enterprisePushEmail);
      this.loggingService.log(`Exporting email with ID: ${this.initialEmailId}`);
      await this.save(false); // Attendre que le template soit sauvegardé et chargé
      const emailId = this.initialEmailId;
      const emailObservable = this.emailStore.selectEmail(emailId);
      const email = await firstValueFrom(emailObservable);
  
      if (email) {
        const requestBody = {
          html: email.html,
          css: email.css,
          minimize: true
        };
  
        if (this.wantsTracking) {
          try {
            const htmlWithTracking = await this.generatorService.addTrackingToUrls(requestBody.html, this.enterpriseTracking).toPromise();
            if (htmlWithTracking) {
              requestBody.html = htmlWithTracking.html;
            }
          } catch (error) {
            console.error('Erreur lors de l\'ajout du tracking :', error);
            this.dialogService.open(this.dialogErrorTracking);
          }
        }
  
        const response = await this.sendToApi('/api/v1/cleaner/v1/compress', requestBody);
  
        if (response) {
          // Vérifiez si la réponse contient 'ampHtml'

          const exportedHtml = response.html;
          const exportedAmpHtml = response.ampHtml || '';
          if (this.enterprisePushEmail === false) {
            if (response.ampHtml) {
              // Create a ZIP file containing both HTML and AMP HTML
              const zip = new JSZip();
              zip.file(`${window.StripoApi.getTitle()}-HTML.html`, exportedHtml);
              zip.file(`${window.StripoApi.getTitle()}-AMP.html`, exportedAmpHtml);
              const content = await zip.generateAsync({ type: 'blob' });
              this.downloadService.saveAs(content, `${window.StripoApi.getTitle()}.zip`);
            } else {
              // Export the normal HTML file without a ZIP
              this.downloadService.saveAs(exportedHtml, `${window.StripoApi.getTitle()}.html`);
            }
          } else if (this.enterprisePushEmail === true) {
            this.generatorService.pushEmail(this.initialEmailId, exportedHtml).subscribe(
              (response) => {
                console.log("Email pushed successfully", response);
  
                if (response.status === 'success') {
                  this.dialogService.open(this.dialogPushEmail, {
                    context: { message: response.message }
                  });
                } else {
                  this.dialogService.open(this.dialogErrorPushEmail, {
                    context: { message: "Échec de l'envoi de l'email." }
                  });
                }
              },
              (error) => {
                console.error("Error pushing email", error);
                this.dialogService.open(this.dialogErrorPushEmail, {
                  context: { message: "Erreur de communication avec le serveur." }
                });
              }
            );
          }
        }
      }
  
    } catch (error) {
      console.error('Erreur lors de l\'exportation :', error);
    } finally {
      this.isExporting = false;
    }
  }
  

  openExportTrackingDialog(): void {
    this.dialogRef = this.dialogService.open(this.dialogExportTracking, {
      context: {},
      hasBackdrop: true,
      closeOnBackdropClick: false,
      closeOnEsc: false,
      autoFocus: false
    });
  }

  private async sendToApi(endpoint: string, body: any): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'ES-PLUGIN-AUTH': `Bearer ${this.authToken}`,
    });
  
    try {
      const response = await this.http.post(`https://plugins.stripo.email${endpoint}`, body, { headers }).toPromise();
      return response;
    } catch (error) {
      console.error('Erreur lors de l\'envoi à l\'API :', error);
      return null;
    }
  }

  public openExternalPreview(): void {
    this.showPopup = true;

    // Utilisation de getTemplate pour récupérer le CSS
    window.StripoApi.getTemplate((html: string, css: string, _width: number, _height: number) => {
        // Une fois que le CSS est récupéré, on utilise compileEmail pour obtenir le HTML et l'AMPHTML
        window.StripoApi.compileEmail((error: string, compiledHtml: string, ampHtml: string, ampErrors: string[]) => {
            if (error) {
                console.error('Erreur lors de la compilation de l\'e-mail:', error);
                return;
            }

            // Stylise le HTML et l'AMPHTML avec le CSS récupéré
            const styledHtml = `<style>${css}</style>${compiledHtml}`;
            const styledAmpHtml = ampHtml ? `<style>${css}</style>${ampHtml}` : '';

            if (ampErrors && ampErrors.length > 0) {
                console.warn('Erreurs AMPHTML:', ampErrors);
            }

            if (this.emailPreviewPopupComponent) {
                // Appelle openPreviewPopup avec le HTML stylé et l'AMPHTML stylé
                this.emailPreviewPopupComponent.openPreviewPopup(styledHtml, styledAmpHtml);
            } else {
                console.error('EmailPreviewPopupComponent n\'est pas encore disponible.');
            }
        }, false); // Le paramètre `false` signifie que le HTML ne sera pas compressé sur une seule ligne
    });
}



public resize(imageUrl: string, newWidth: number, newHeight: number, imageElement: any): void {
  const ratio = newWidth / newHeight;
  this.imageSelectionService.resetImageUrls();

  this.generatorService.resizeImageRatio(imageUrl, ratio).subscribe(
    response => {
      // Mise à jour des URLs des images une fois la réponse reçue
      this.imageSelectionService.updateImageUrls([response['url'], response['url_1'], response['url_2']]);
      // console.log(response);
    },
    error => {
      console.error('Erreur lors du redimensionnement de l\'image :', error);
    }
  );
}

public write(imageUrl: string, textUp: any, textDown: any, imageElement: any, single_text: any): void {
  this.imageSelectionService.resetImageOptions();
  const originalImageUrl = imageElement.attr('src');
  
  // Open dialog immediately with loading state
  const dialogRef = this.dialogService.open(WriteOnImageComponent, {
    context: {
      staticImageUrl: '',
    },
    hasBackdrop: true,
    closeOnBackdropClick: false,
    closeOnEsc: false,
    autoFocus: false
  });

  // Make the API call after dialog is opened
  (this.generatorService.writeOnimage(imageUrl, textUp, textDown, single_text) as Observable<any>).subscribe(
    (response: any) => {
      if (response.statut === 'ok') {
        // Update the service with the options
        this.imageSelectionService.updateImageOptions([
          response.text_image_center,
          response.text_image_left,
          response.text_image_right
        ]);
        
        // Only update staticImageUrl directly since imageOptions will come through the service
        dialogRef.componentRef.instance.staticImageUrl = response.resized_original_url;
      }
    },
    error => {
      console.error('Erreur lors du chargement des options d\'images', error);
      this.dialogRef = this.dialogService.open(this.dialogErrorWriteOnImage);
      dialogRef.close();
      imageElement.next('.esd-banner-generating').remove();
      imageElement.show();
    }
  );

  // Handle dialog close
  dialogRef.onClose.subscribe((result: { mergedImageUrl?: string } | null) => {
    if (result && result.mergedImageUrl) {
      imageElement.attr('src', result.mergedImageUrl);
    } else {
      if (result === undefined) {
        this.dialogRef = this.dialogService.open(this.dialogErrorWriteOnImage);
      } else {
        imageElement.attr('src', originalImageUrl);
      }
    }
    imageElement.next('.esd-banner-generating').remove();
    imageElement.show();
  });
}

private async processImages(html: string): Promise<string> {
  const regex = /https:\/\/storage\.googleapis\.com\/[^"']+?\.(jpeg|jpg|png|gif)/g;
  const googleImageUrls = Array.from(html.matchAll(regex), m => m[0]);
  const urlMapping: Record<string, string> = {};
  await Promise.all(googleImageUrls.map(async (url) => {
    const imageBlob = await this.downloadImage(url);
    const stripoImageUrl = await this.uploadImageToStripo(imageBlob, String(this.initialEmailId), this.userId);
    urlMapping[url] = stripoImageUrl;
  }));

  return this.replaceImageUrls(html, urlMapping);
}

private async downloadImage(url: string): Promise<Blob> {
  const response = await fetch(url);
  if (!response.ok) {
      throw new Error(`Failed to download image: ${url}`);
  }
  const blob = await response.blob();
  return blob;
}

private getImageExtension(mimeType: string): string {
  switch (mimeType) {
    case 'image/jpeg':
      return 'jpg';
    case 'image/jpg':
      return 'jpg';
    case 'image/png':
      return 'png';
    case 'image/gif':
      return 'gif';
    // ajoutez d'autres types d'image si nécessaire
    default:
      throw new Error(`Unsupported MIME type: ${mimeType}`);
  }
}

private async uploadImageToStripo(imageBlob: Blob, emailId: string, userId: number): Promise<string> {
  const extension = this.getImageExtension(imageBlob.type);
  const formData = new FormData();
  formData.append('file', imageBlob, `image.${extension}`);
  formData.append('key', `user_${userId}`);

  if (!this.authToken || this.isTokenExpired(this.authToken)) {
    this.getAuthTokenCallback();
    throw new Error('Auth token is not set');
  }

  const response = await fetch('https://plugins.stripo.email/api/v1/documents/v3/documents', {
      method: 'POST',
      headers: {
          'Es-Plugin-Auth': `Bearer ${this.authToken}`,
          'Es-Plugin-Ui-Data': JSON.stringify({ emailId, UserId: userId }),
          // ... autres entêtes
      },
      body: formData,
  });

  const data = await response.json();
  return data.url;  // Supposons que l'URL de l'image est retournée dans la réponse
}

private replaceImageUrls(html: string, urlMapping: Record<string, string>): string {
  const modifiedHtml = html.replace(/https:\/\/storage\.googleapis\.com\/[^"']+?\.(jpeg|jpg|png|gif)/g, (match) => {
      return urlMapping[match] || match;
  });
  return modifiedHtml;
}

private async initImages(email: Email): Promise<void> {
  const authRes = await this.getAuthToken().toPromise();
  this.authToken = authRes!.token;

  email.html = await this.processImages(email.html!); 
}

public async initEditor(email: Email): Promise<void> {
  this.isLoading = true; // Démarrez le chargement
  try {
    this.loggingService.log(`Initializing editor for email ID: ${this.initialEmailId}`);
    await this.initImages(email);
    this.initStripo(email);
  } finally {
    this.isLoading = false; // Terminez le chargement une fois l'initialisation terminée
  }
}


  private getAuthToken(): Observable<{ token: string }> {
    return this.http.post<{ token: string }>(
      'https://plugins.stripo.email/api/v1/auth',
      JSON.stringify({
        pluginId: 'aca51dc926234c86855d2af2433d7b8c',
        secretKey: 'd7c2297e5cf54f3fbe27477f7d04aeaf',
      }),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).pipe(
      catchError(error => {
          throw error;
      })
    );
}

  private initForm(): void {
    this.form = this.formBuilder.group({
      title: this.formBuilder.control(''),
      hiddenPreHeader: this.formBuilder.control(''),
    });
  }

  private updateForm(): void {
    this.form.get('title')!.setValue(window.StripoApi.getTitle(), { emitEvent: false });
    this.form.get('hiddenPreHeader')!.setValue(window.StripoApi.getHiddenPreHeader(), { emitEvent: false });
  }

  private listenForm(): void {
    this.form
      .get('title')!
      .valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000))
      .subscribe((value) => {
        window.StripoApi.setTitle(value);
      });

    this.form
      .get('hiddenPreHeader')!
      .valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000))
      .subscribe((value) => {
        window.StripoApi.setHiddenPreHeader(value);
      });
  }

  private async processAndSaveTemplate(): Promise<{ html: string, css: string }> {
    try {
      const { html, css } = await this.getTemplateFromEditor();
      this.loggingService.log(`Processing and saving template for email ID: ${this.initialEmailId}`);
      // console.log('HTML de base:', html);
      //let optimizedHtml:  {html: string} = {html: ''};
      //try {
      //  optimizedHtml = await lastValueFrom(this.generatorService.resizeImagesInHtml(html));
      //} catch (error) {
      //    console.error('Erreur lors de l\'optimisation des images :', error);
      //  }
      const processedHtml = await this.processImages(html);
      // console.log('HTML traité:', processedHtml);
      return { html: processedHtml, css };
    } catch (error) {
      console.error('Erreur lors du traitement des images :', error);
      throw error;
    }
  }

  private async getTemplateFromEditor(): Promise<{ html: string, css: string }> {
    return new Promise((resolve, reject) => {
      window.StripoApi.getTemplate((html: string, css: string) => {
        resolve({ html, css });
      });
    });
  }

private initStripo(email: Email): Promise<void> {
  return new Promise(() => {
      this.initializeStripo(email);
      this.addImageResizeControl();
  });
}

private initializeStripo(email: Email): void {
  window.Stripo.init({
    settingsId: 'stripoSettingsContainer',
    previewId: 'stripoPreviewContainer',
    codeEditorButtonId: 'codeEditor',
    undoButtonId: 'undoButton',
    redoButtonId: 'redoButton',
    userFullName: this.userName,
    locale: 'fr',
    html: email.html!,
    css: email.css!,
    editorFonts: {
      showDefaultStandardFonts: true,
      showDefaultNotStandardFonts: true,
      customFonts: [
        {
          name: "Montserrat",
          fontFamily: "'Montserrat', 'helvetica neue', helvetica, arial, sans-serif",
          url: "https://fonts.googleapis.com/css?family=Montserrat&display=swap"
        }
      ]
    },
    apiRequestData: this.getApiRequestData(),
    getAuthToken: this.getAuthTokenCallback(),
    versionHistory: {
      userFullName: this.userName,
      changeHistoryLinkId: "changeHistoryLink",
      onInitialized: (lastChangeInfoText: string) => {
        const changeHistoryLink = document.getElementById('changeHistoryLink');
        if (changeHistoryLink) {
          changeHistoryLink.innerHTML = '<i class="bi bi-clock-history"></i>';
        }
      }
    },
    onTemplateLoaded: () => {
      this.updateForm();
      this.listenForm();
    },
    onDataChanged: () => {
      this.updateForm();
    },
  });
}


private getApiRequestData(): any {
  const envPrefix = window.location.hostname === 'app.deebr.co' ? 'prod-' : 'preprod-';
  return {
      emailId: envPrefix + this.initialEmailId,
      UserId: this.userId,
  };
}

private getAuthTokenCallback(): (callback: any) => void {
  return (callback: any) => {
      if (this.authToken && !this.isTokenExpired(this.authToken)) {
        // console.log('Token is still valid:', this.authToken);
        callback(this.authToken);
      } else {
        // console.log('Token is expired or missing, fetching new token...');
        this.getNewAuthToken(callback);
      }
  };
}

private isTokenExpired(token: string): boolean {
  const payload = token.split('.')[1];
  const decoded = JSON.parse(atob(payload));
  const exp = decoded.exp * 1000; // Convertir UNIX timestamp en milliseconds
  return Date.now() > exp;
}

private getNewAuthToken(callback: Function): void {
  this.getAuthToken().subscribe({
      next: (res: { token: string }) => {
          this.authToken = res.token;
          // console.log('New token received:', res);
          callback(res.token);
      },
      error: () => {
        console.log('Failed to fetch new token');
        callback(null);
      },
  });
}

private addImageResizeControl(): void {
  const stripoImageResizeControl = this.createImageResizeControl();
  window.StripoApi.initExternalSettingControls = (controlsList: any[]) => {
      controlsList.push(stripoImageResizeControl);
  };
  window.StripoApi.initExternalSettingsPanels = this.initExternalSettingsPanels;
}

private createImageResizeControl(): any {
  const self = this;
  return {
      name: 'stripoImageResizeControl',
      render: function(this: { jContainer: any; jElement: any }) {
        const controlHtml = `
        <div style="display: flex; gap: 10px;">
           
            <button id="majRemiseProduct" type="button" class="btn btn-success" style="display: flex; align-items: center; justify-content: center;">
                <strong style="font-size: 19px; margin-right: 5px;">%</strong> Mettre à jour la remise
            </button>
        </div>

        <hr class="setting-control-hr-0">
        <div class="esdev-setting-control">
            <button id="resizeImageButton" type="button" class="btn btn-success"><span class="es-icon-magic"></span> Redimensionnement IA</button>
            <button id="imageToGifButton" style="margin-left:10px;" type="button" class="btn btn-success">
              <span class="es-icon-image"></span> Animation IA
            </button>
            <hr class="setting-control-hr-1">
            <!-- Nouveaux contrôles pour écrire sur l'image -->
            <label>Texte Haut :</label>
            <input type="text" id="textUp" placeholder="Texte en haut" class="form-control">
            <label>Texte Bas :</label>
            <input type="text" id="textDown" placeholder="Texte en bas" class="form-control">
            <br/>
            <button id="writeOnImageButton" type="button" class="btn btn-success"><span class="es-icon-edit"></span> Écrire sur l'image</button>
            <!-- Nouveaux contrôles pour creer un gif à partir l'image -->
            
        </div>
        `;

        this.jContainer.parent().children().first().after(controlHtml);

        //console.log('HTML du conteneur parent:', this.jContainer.parent().html());


        // Récupérer et initialiser les valeurs de largeur et hauteur depuis l'image
        const imgElement = $('img[accessor="imageElement"]');
        const imgUrl = imgElement.attr('src');

        if (imgUrl) {
          const img = new Image();
          img.src = imgUrl;

          img.onload = function() {
            const width = img.width;
            const height = img.height;
            console.log('Dimensions de l\'image:', width, 'x', height);

            $('#newWidth').val(width);
            $('#newHeight').val(height);
          };

          img.onerror = function() {
            console.error('Erreur de chargement de l\'image:', imgUrl);
          };
        } else {
          console.error('URL de l\'image non trouvée');
        }

        $('#resizeImageButton').click(() => self.onResizeImageButtonClick(this.jElement));
        $('#writeOnImageButton').click(() => self.onWriteOnImageButtonClick(this.jElement));
        $('#imageToGifButton').click(() => self.onimageToGifButtonClick(this.jElement));
        $('#majRemiseProduct').click(() => self.onmajRemiseProduct(this.jElement));
      }
  };
}

private onmajRemiseProduct(jElement: any): void {
  console.log("coucou")
  const imageElement = jElement.find('img');
  const imageUrl = imageElement.attr('src');
  const aElement = jElement.find('a[href]');
  const htmlUrl = aElement.attr('href');
  const width = imageElement.attr('width');
  
  console.log(imageUrl)
  console.log(htmlUrl)
  if (imageUrl && htmlUrl) {
    console.log("appel")
    // Remplacer l'image par un spinner
    //imageElement.hide();
    imageElement.after(`<div class="esd-banner-generating" style="left: 0px; width: ${width};"> 
    <div class="loader-c">
      <div class="loader-z"></div>
    </div>           
</div>`);

this.generatorService.majRemiseProduct(imageUrl, htmlUrl, width).subscribe(
  response => {
    console.log(response);
    imageElement.next('.esd-banner-generating').remove();
    imageElement.attr('src', response).show();
  },
  error => {
    console.error('Erreur lors du redimensionnement de l\'image :', error);
  }
);
}
}

private onResizeImageButtonClick(jElement: any): void {
  const imageElement = jElement.find('img');
  const imageUrl = imageElement.attr('src');
  const supportedFormats = ['jpeg', 'jpg', 'png', 'webp'];
  const imageExtension = imageUrl.split('.').pop().toLowerCase();

  if (!supportedFormats.includes(imageExtension)) {
    this.errorMessage = "Uniquement les format jpeg, png et webp sont supportés pour le redimensionnement IA"
    this.dialogRef = this.dialogService.open(this.dialogErrorAnimate)
    return;
  }

  const img = new Image();
  img.src = imageUrl;

  img.onload = () => {
    this.selectedImageUrl = imageUrl;
    this.imgWidth = img.width;
    this.imgHeight = img.height;

    this.dialogService.open(ResizeImageModalComponent, {
      context: {
        imageUrl: this.selectedImageUrl,
        imgWidth: this.imgWidth,
        imgHeight: this.imgHeight,
        jElement: jElement,
      },
    }).onClose.subscribe(result => {
      if (result) {
        this.afterResizeImageButtonClick(result.jElement, result.newWidth, result.newHeight, result.imageUrl);
      }
    });
  };
}


private afterResizeImageButtonClick(jElement: any, newWidth:number, newHeight:number,imageUrl:string ): void {
  const imageElement = jElement.find('img');
  const width = imageElement.attr('width');

  if (!isNaN(newWidth) && !isNaN(newHeight)) {
    // Remplacer l'image par un spinner
    //imageElement.hide();
    imageElement.after(`<div class="esd-banner-generating" style="left: 0px; width: ${width};"> 
    <div class="loader-c">
      <div class="loader-z"></div>
    </div>           
</div>`);

    this.resize(imageUrl, newWidth, newHeight, imageElement);
  } else {
    console.error('Erreur : Largeur ou Hauteur invalide');
  }
}

onimageToGifButtonClick(jElement: any): void {
  const imageElement = jElement.find('img');
  const imageUrl = imageElement.attr('src').split('?')[0];
  const width = imageElement.attr('width');
  if (width < 500){
    this.errorMessage = "Votre image est trop petite pour être animée. Le bloc doit faire 500px de largeur minimum."
    this.dialogRef = this.dialogService.open(this.dialogErrorAnimate)
    return;
  }
  console.log('Image width:', width);

  const supportedFormats = ['jpeg', 'jpg', 'png', 'webp'];
  const imageExtension = imageUrl.split('.').pop().toLowerCase();

  if (!supportedFormats.includes(imageExtension)) {
    this.errorMessage = "Uniquement les format jpeg, png et webp sont supportés pour l'animation."
    this.dialogRef = this.dialogService.open(this.dialogErrorAnimate)
    return;
  }

  const dialogRef = this.dialogService.open(ImageToGifDialogComponent, {
    context: { imageUrl: imageUrl },
    hasBackdrop: true,
    closeOnBackdropClick: false,
    closeOnEsc: false,
    autoFocus: false,
    dialogClass: 'custom-dialog'
  });

  dialogRef.componentRef.instance.imageUrl = imageUrl;

  dialogRef.onClose.subscribe(result => {
    if (result) {
      imageElement.attr('src', result);
    }
  });
}



private onWriteOnImageButtonClick(jElement: any): void {
  const imageElement = jElement.find('img');
  const imageUrl = imageElement.attr('src');
  const width = imageElement.attr('width');
  const textUp = $('#textUp').val();
  const textDown = $('#textDown').val();
  const single_text = "false";

  if (textUp || textDown) {
    imageElement.after(`<div class="esd-banner-generating" style="left: 0px; width: ${width};"> 
    <div class="loader-c">
      <div class="loader-z"></div>
    </div>           
</div>`);
    this.write(imageUrl, textUp, textDown, imageElement, single_text);
  } else {
    console.error('Erreur : Texte Haut ou Texte Bas vide');
  }
}

  private initExternalSettingsPanels(panelsList: any[]): void {

    const imgPanel = panelsList.find((p: any) => p.name === 'imgBlockSettingsPanel');
    if (imgPanel && !imgPanel.controls.includes('stripoImageResizeControl')) {
        imgPanel.controls.push('stripoImageResizeControl');
    }
  }

  closeDialog() {
    this.dialogRef?.close();
  }

  public async exportSocialMedia(): Promise<void> {
    this.loading = true;
    await this.save(false); // Wait for the template to be saved and loaded
    setTimeout(() => {
      this.generatorService.exportSocial(this.previewImgUrl!, this.previewHtmlUrl!)
        .subscribe(
          response => {
            const blob = new Blob([response], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'social_media.zip';
            document.body.appendChild(a); // Required for Firefox
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            this.loading = false;
          },
          error => {
            this.dialogRef = this.dialogService.open(this.dialogErrorExport);
            this.loading = false;
          }
        );
    }, 2000);
  }
}

declare var window: any;
