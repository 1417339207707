<nb-card class="write-on-image-choice">
  <nb-card-header>
    Choisissez et ajustez votre texte
  </nb-card-header>
  <nb-card-body>
    <div class="write-on-image-options">
      <!-- Show loading state when isLoading is true or imageOptions is empty -->
      <div *ngIf="isLoading || !imageOptions?.length" class="loading-container">
        <div class="loading-spinner">
          <div class="spinner"></div>
        </div>
      </div>
      
      <!-- Show content only when not loading and options are available -->
      <div [hidden]="isLoading || !imageOptions.length || !staticImageUrl">
        <div class="overlay-container" #overlayContainer>
          <img [src]="staticImageUrl" class="static-image" #staticImgEl>
          <div class="overlay-wrapper">
            <img #overlayImg [src]="currentOverlayUrl" class="overlay-image" (mousedown)="onImageMouseDown($event)" />
            <div class="resize-handle top-left" (mousedown)="onResizeHandleDown($event, 'nw')" *ngIf="!isLoading"></div>
            <div class="resize-handle top-right" (mousedown)="onResizeHandleDown($event, 'ne')" *ngIf="!isLoading"></div>
            <div class="resize-handle bottom-left" (mousedown)="onResizeHandleDown($event, 'sw')" *ngIf="!isLoading"></div>
            <div class="resize-handle bottom-right" (mousedown)="onResizeHandleDown($event, 'se')" *ngIf="!isLoading"></div>
          </div>

          <!-- Grey overlay and spinner appear on top when isLoading is true -->
          <div class="overlay-loading" *ngIf="isLoading">
            <div class="overlay-loading-bg"></div>
            <div class="loading-spinner">
              <div class="spinner"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Always show the alignment options -->
      <div class="thumbnail-container">
        <div class="thumbnail" *ngFor="let option of imageOptions; let i = index">
          <i class="bi" 
             [class.bi-text-center]="i === 0"
             [class.bi-text-left]="i === 1"
             [class.bi-text-right]="i === 2"
             [class.active]="selectedOption === option"
             [class.disabled]="isLoading"
             (click)="selectOption(option)"
             style="font-size: 2rem; cursor: pointer;">
          </i>
        </div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="footer-buttons">
    <button nbButton status="danger" [disabled]="loading || isLoading" (click)="cancel()">Annuler</button>
    <div class="right-buttons">
      <button nbButton status="success" [disabled]="loading || isLoading" (click)="confirm()">
        <div class="button-content">
          Confirmer
          <div *ngIf="loading" class="loading-spinner"></div>
        </div>
      </button>
    </div>
  </nb-card-footer>
</nb-card>
