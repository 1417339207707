import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ImageSelectionService } from '../../service/image-selection.service';

@Component({
  selector: 'app-firefly-choice',
  templateUrl: './firefly-choice.component.html',
  styleUrls: ['./firefly-choice.component.scss']
})

export class FireflyChoiceComponent implements OnInit {
  constructor(protected dialogRef: NbDialogRef<FireflyChoiceComponent>,
    private imageSelectionService: ImageSelectionService
    ) {}

ngOnInit(): void {
  this.imageSelectionService.imageUrls$.subscribe(urls => {
    if (urls.length > 0) {
      this.isLoading = false;
      this.imageUrls = urls;
      this.selectedImageUrl = urls[0];
    } else {
      this.isLoading = true; // Assurez-vous que isLoading est vrai au début ou quand les URLs sont réinitialisées
    }
  });
}

  placeholderCount = 5;
  selectedImageUrl: string = '';
  isLoading: boolean = true; // Ajoutez cet état pour gérer l'affichage du spinner

  private _imageUrls: string[] = [];

  @Input()
  set imageUrls(urls: string[]) {
    if (urls && urls.length > 0) {
      this._imageUrls = urls;
      this.selectedImageUrl = urls[0];
      this.isLoading = false; // Désactivez le spinner une fois les images chargées
    }
  }

  get imageUrls(): string[] {
    return this._imageUrls;
  }

  selectImage(url: string, event: MouseEvent) {
  event.stopPropagation(); // Empêche l'événement de se propager
  this.selectedImageUrl = url;
}

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.selectedImageUrl);
  }

}
